<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{store.name}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.stores.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="store.name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="store.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Store from "../../../models/Store";

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                store: {}
            }
        },
        async mounted() {
            const storeId = this.$route.params.id
            this.store = await Store.find(storeId)
        },
    }
</script>

<style scoped>

</style>
